




























































import {Component, Vue} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import {
    Blockquote,
    Bold,
    BulletList,
    HardBreak,
    Heading,
    HorizontalRule,
    Image,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Strike,
    TiptapVuetify,
    Underline
} from "tiptap-vuetify";
import ViewDesign from "@/components/global/ViewDesign.vue";
import {globalEventBus} from "@/main";

@Component({components: {TiptapVuetify, PersistentDialog, ViewDesign}})
export default class Medicalbag extends Vue {
    private readonly extensions: any[] = [
        [
            Heading,
            {
                // Options that fall into the tiptap's extension
                options: {
                    levels: [1, 2, 3]
                }
            }
        ],
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem, // if you need to use a list (BulletList, OrderedList)
        BulletList,
        OrderedList,
        Link,
        Blockquote,
        HorizontalRule,
        HardBreak, // line break on Shift + Ctrl + Enter
        [Image, {target: '_parent'}]
    ];

    private content!: string;
    private savedContent: string = '';
    private loading: boolean = false;
    private editMode: boolean = false;
    private showError: boolean = false;
    private emergencyBagId: number = 0;

    private get toolbarAttributes() {
        if (this.$store.getters.darkmode) {
            return {color: 'unset', light: false, dark: true};
        }
        return {color: 'unset', light: true, dark: false};
    }

    private save() {
        this.content = this.content.replaceAll("<a href", "<a target='_blank' href");
        const byteSize = new Blob([this.content]).size;
        const mbSize = byteSize / 1024 / 1024;
        if (mbSize <= 8) {
            this.loading = true;
            this.$webApi.storeEmergencyBag(this.content, this.emergencyBagId).then(resultId => {
                this.emergencyBagId = resultId;
                this.savedContent = this.content;
            }).catch(() => {
                this.content = this.savedContent;
                this.showError = true;
            }).finally(() => {
                this.editMode = false;
                window.scrollTo(0, 0);
                this.loading = false;
            });
        } else {
            const persistentDialog = this.$refs.persistentDialog as PersistentDialog;
            persistentDialog.showInfo("Warning", this.$tc('medicalBag.sizeExceeded'));

        }
    }

    cancel(): void {
        this.editMode = false;
        this.content = this.savedContent;
    }

    mounted() {
        this.loadContent();
        globalEventBus.$on('swipeLeft',()=>this.editMode = true);
    }
    destroyed(){
        globalEventBus.$off('swipeLeft');
    }
    private async loadContent() {
        this.loading = true;
        await this.$webApi.readEmergencyBag().then(result => {
            if (result && result?.text !== '') {
                this.emergencyBagId = result.id;
                this.content = this.savedContent = result.text;
            } else {
                this.content = this.savedContent = this.$t('medicalBag.emptyEmergencyBag').toString();
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    private clearText(): void {
        this.content = "";
        this.$forceUpdate();
    }
}
